import React from 'react'
import { BrowserRouter} from "react-router-dom";
import Routing from './Routing'
import { store } from '../src/store/store';
import { Provider } from 'react-redux';

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routing/>
      </Provider> 
    </BrowserRouter>
  );
}

export default App