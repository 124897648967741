import React, { ReactNode, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { MenuIcon,Projects,ChevronDown, Document, Users } from "../../assets/Icons";
import { getItemFromLocal, getRoles } from "../../Util/utils";

type LayoutProps = {
  children: ReactNode;
};
type NavigationProps = {
  name: string;
  icon: any;
  href: string;
  current: boolean;
  isVisible: boolean;
};

const Layout = ({ children }: LayoutProps) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const location = useLocation();

  const main = (
    <main
      className="mt-14"
      style={{
        width: showSidebar ? "calc(100vw - 320px)" : "100vw",
      }}
    >
      {children}
    </main>
  );

  const role = getRoles();
  const isAdmin = role ? role[0] === "ADMIN" : false;


  

  const navigation: Array<NavigationProps> = [
    {
      name: "Documents",
      icon: Document,
      href: "/documents",
      current: location.pathname === "/documents",
      isVisible: true,
    },
    {
      name: "Projects",
      icon: Projects,
      href: "/projects",
      current: location.pathname === "/projects",
      isVisible: isAdmin,
    },
    {
      name: "Users",
      icon: Users,
      href: "/users",
      current: location.pathname === "/users",
      isVisible: isAdmin,
    },
  ];

  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    setShowSidebar(!showSidebar);
  };

  const handleClick = (item: NavigationProps) => {
    if (item.isVisible) {
      navigate(item.href);
    }
  };

  const topbar = (
    <nav
      id="navbar"
      className={
        "z-[2] h-20 fixed top-0 flex bg-white px-4 items-center flex-row justify-between border-b border-gray-300 py-4"
      }
      style={{
        width: showSidebar ? "calc(100vw - 320px)" : "100vw",
      }}
    >
      <div className="flex flex-row items-center justify-between">
        {!showSidebar && (
          <button
            id="btnSidebarToggler"
            type="button"
            className="text-2xl text-black"
            onClick={handleSidebarToggle}
          >
            <div className="h-10 w-10 flex items-center justify-center rounded-full bg-[#F8F9FA]">
              <img src={MenuIcon} alt="Menu" />
            </div>
          </button>
        )}
        <div className="cursor-pointer relative mr-4">
          {navigation.map(
            (item, index) =>
              item.current && (
                <div
                  key={index + 1}
                  className="text-20 font-medium font-Avenir text-[#3C424C]"
                >
                  <div className="ml-2 text-2xl font-semibold">
                    {" "}
                    {item.name}
                  </div>
                </div>
              )
          )}
        </div>
      </div>

      <section className="flex">
        <div className="text-20 font-medium font-Avenir text-[#3C424C] ml-2 mr-[5rem]">
          <div className="flex flex-row">
              <Menu as="div" className="relative ml-3 mr-9">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full shadow bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                    <div className="ml-3 hidden text-sm font-medium text-gray-700 lg:block ">
                      {getItemFromLocal('userEmail')}
                    </div>
                    <img src={ChevronDown} alt="down-icon"  className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"/>
                  </Menu.Button>
                </div>
                <Menu.Items className="cursor-pointer absolute z-100 right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                  <Menu.Item>
                    {() => (
                      <button
                        className={"block px-4 py-2 text-sm text-gray-700"}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
        </div>
      </section>
    </nav>
  );

  const sidebar = (
    <div id="containerSidebar" className="z-[999]">
      <div className="navbar-menu relative z-[999]">
        <nav
          id="sidebar"
          className={`fixed h-full flex w-3/4 flex-col top-0 overflow-x-hidden overflow-y-auto bg-white pb-2 sm:max-w-xs lg:w-80 border-r border-gray-200 ${
            showSidebar ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div>
            <div className="flex flex-row items-center">
              <button
                type="button"
                className="text-xl text-white m-4 mt-5"
                onClick={handleSidebarToggle}
              >
                <div className="h-10 w-10 flex items-center justify-center rounded-full bg-[#F8F9FA]">
                  <img src={MenuIcon} alt="Menu" />
                </div>
                {/* <img className="ml-2" src={MenuIcon} alt="ICON" />  title logo*/}
              </button>
              <h2 className="text-2xl text-primary cursor-default font-extrabold">
                DocShare
              </h2>
            </div>
          </div>

          <div className="mt-5">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map(
                    (item, index) =>
                      item.isVisible && (
                        <button
                          className={`cursor-pointer px-5 py-2 mx-auto w-[90%] rounded-3xl hover:bg-lightPrimary text-[14px] font-medium text-gray-500 flex flex-row ${
                            item.current
                              ? "bg-darkPrimary  px-3 bg-opacity-20"
                              : ""
                          }`}
                          key={index + 1}
                          onClick={() => handleClick(item)}
                        >
                          <img
                            src={item.icon}
                            alt={item.name}
                            className="mr-4 text-[#3944BC] h-7 w-7"
                          />
                          <span className="my-auto">{item.name}</span>
                        </button>
                      )
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="mx-auto lg:ml-80"></div>
    </div>
  );

  return (
    <div className="flex flex-row bg-[#f8f9fd] overflow-hidden min-h-screen font-raleway">
      {showSidebar ? sidebar : null}
      <div className="w-full ">
        {topbar}
        {main}
      </div>
    </div>
  );
};

export default Layout;