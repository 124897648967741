import React, { lazy, useEffect, Suspense } from 'react'
import { useDispatch } from 'react-redux';
import { Routes,Route, useLocation, useNavigate } from 'react-router-dom'
import Layout from '../Components/Layout';
import Loader from '../Components/ReusableComponents/Loader';

type LayoutBarProps = {
  component: React.ComponentType;
};

/****** LOAD ROUTES ******/
// Set up all routers as lazy routes. THis is important because when the app loads, we want the landing page to split load quickly for SEO purposes.
// Hence make it default to setup all routes as lazy routes.
const Login = lazy(() => import('../Components/Auth/Login'));
const OTP = lazy(() => import('../Components/Auth/OTP'));
const ManageProjects = lazy(() => import('../Components/Projects/ManageProjects'));
const Documents = lazy(() => import('../Components/Documents/Documents'));
const Users = lazy(() => import('../Components/Users/ManageUsers'));


const LayoutBar = ({ component: Component } : LayoutBarProps) => {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("isLogin");
  // const expiryTimeMs = timeStringToTimestamp(
  //   localStorage.getItem("expiryTime")
  // );
  const currentDate = new Date();
  // Create a new date object for the start of the current day
  const startOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  // Calculate the elapsed time for the current day in milliseconds
  const currentTimeMs = currentDate.getTime() - startOfDay.getTime();
  // useEffect(() => {
  //   if (!isLogin) {
  //     navigate("/login");
  //     //clearCahce();
  //     return;
  //   }
  // }, []);
  const dispatch = useDispatch();
  const location = useLocation();

  // useEffect(() => {
  //   if(location.pathname !== '/addEmployee0'){
  //     // dispatch(updateEmployeeJson(employeeJson));
  //     // dispatch(clearErrorState({ step0: {}, step1: {}, step2: {}, step4:{} }));
  //     // dispatch(clearErrorStateWork({ step0: {}, step1: {}, step2: {}, step3:{} }));
  //     // dispatch(updateErrorMessage(false));
  //   }
  //   if (!location.pathname.includes('/view/')) {
  //     // dispatch(updateFeildsDisabled(false));
  //   }
  //   if(location.pathname !== '/holidayCalendar'){
  //     // dispatch(clearCalendar());
  //   }
  // })

  //let token = getToken('Token');

  // const checkExpiry = () => {
  //   let timeRef = null;
  //   if(token){
  //     const decoded = jwtDecode(token);
  //     const expiryTime = decoded.exp ? (new Date(decoded.exp * 1000)).getTime() : 0;
  //     const currentTime = (new Date()).getTime();
  //     const timeout = expiryTime - currentTime;
  //     const onExpiry = () => {
  //       navigate("/login?sessionExpired=true");
  //       localStorage.clear();
  //     }
  //     if(timeout > 0){
  //       timeRef = setTimeout(onExpiry,timeout)
  //     }else{
  //       onExpiry()
  //     }
  //     return () => {
  //       if (timeRef) clearTimeout(timeRef);
  //     };
  //   }
  // }

  // checkExpiry();

  return (
    <div>
      <Layout>
         <div className='mt-20 font-raleway'> {/* to Skip the topBar */}
          <Component />
        </div>
      </Layout>
    </div>
  );
};


const Routing = () => {
  return (
    <Suspense fallback={<div className='w-screen h-screen bg-white flex justify-center items-center'><Loader/></div>}>
      <Routes>
        <Route index element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<OTP />} />
        <Route
          path="/projects"
          element={<LayoutBar component={ManageProjects} />}
        />
        <Route
          path="/documents"
          element={<LayoutBar component={Documents} />}
        />
        <Route
          path="/users"
          element={<LayoutBar component={Users} />}
        />
      </Routes>
    </Suspense>
  );
}

export default Routing