import { createSlice } from "@reduxjs/toolkit";


export interface Project {
    _id: string;
    isActive: boolean;
    name: string;
    description: string;
}

export interface ProjectState {
    allProjects: Array<Project | null>;
    loading: boolean;
    dialogOpen: boolean;
    dialogLoading: boolean;
    formProjectName : string;
    formDescription : string;
    searchProject : string;
    filteredProjects :  Array<Project | null>;
}

const initialState : ProjectState = {
    allProjects : [],
    loading:false,
    dialogOpen:false,
    dialogLoading:false,
    formProjectName : '',
    formDescription : '',
    searchProject : '',
    filteredProjects : [],
}

export const projectSlice = createSlice({
    name: 'projectSlice',
    initialState,
    reducers:{
        setAllProjects:(state,action) => {
            state.allProjects = action.payload;
        },
        setLoading:(state,action) => {
            state.loading = action.payload;
        },
        setDialogOpen:(state,action) => {
            state.dialogOpen = action.payload;
        },
        setDialogLoading:(state,action) => {
            state.dialogLoading = action.payload;
        },
        setFormProjectName:(state,action) => {
            state.formProjectName = action.payload;
        },
        setFormDescription:(state,action) => {
            state.formDescription = action.payload;
        },
        setProjectSearch:(state,action) => {
            state.searchProject = action.payload;
        },
        setFilteredProjects:(state,action) => {
            state.filteredProjects = action.payload;
        },
        resetProjectState: () => initialState,
    }
})

export const {setAllProjects,setLoading,setDialogOpen,setDialogLoading,setFormProjectName,setFormDescription,setProjectSearch,setFilteredProjects,resetProjectState} = projectSlice.actions;

export default projectSlice.reducer;