
import axios, { AxiosError } from "axios";
import { DecodedToken } from "../Components/Auth/OTP";
import { jwtDecode,JwtPayload } from "jwt-decode";
import { toast } from "react-toastify";
import { cloudServiceUrl,cloudApplicationUrl } from "../api/apiConfig";

export const upload_file_to_s3 = async (file: File) => {

  let tokenExpired = cloudTokenExpiry();
  let cloudServiceToken;
  if(tokenExpired){
    cloudServiceToken = await loginToCloudService(); 
  }
 
  try {
    const url = `${cloudServiceUrl}s3/upload`;

    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: {
        Authorization : `Bearer ${cloudServiceToken || getItemFromLocal('cloudToken')}`,
        Accept:"*/*",
        'applicationurl' : cloudApplicationUrl,
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status == 200) {
      return res.data;
      //set the response object to any state
    }
  } catch (err) {
    if(err instanceof AxiosError){
      const statusCode = err.response?.status;
      if (statusCode === 413) {
        toast.error('File size exceeds more than 10MB');
      }
      else{
        toast.error('File Upload Unsuccessful')
      }
    }
    //if any error is handle here
  }
};
export const getFile = async(file_name : string)=>{

  let tokenExpired = cloudTokenExpiry();
  let cloudServiceToken;
  if(tokenExpired){
    cloudServiceToken = await loginToCloudService(); 
  }
 
  try {
      const url = `${cloudServiceUrl}s3/generate/presigned/url`;
      let payload = {
        'objectKey': file_name
      }
      const response = await axios.post(url,payload,{ headers: {
        Authorization : `Bearer ${cloudServiceToken || getItemFromLocal('cloudToken')}`,
        'applicationurl' : cloudApplicationUrl,
      } });
      if(response.status === 200){
        return response?.data?.data;
      }
      return ''; 
    } catch (error) {
      toast.error('Failed to fetch the file')
      console.error("Error fetching data:", error);
      return '';
    }
}
export const loginToCloudService =async () => {
  try{
    // const url = `${cloudServiceUrl}auth/login`;
    const url = `${cloudServiceUrl}auth/get/token`;
    const payload = {
      "userName": "aWN0c3lzdGVtc0BtYWlsaW5hdG9yLmNvbQ==",
      "password": "MTIzNDU2Nzg5"
    }
    const response = await axios.post(url, payload)
    if(response.status === 200){
      setItemForLocal('cloudToken',response.data?.data);
      return response.data?.data;
    }
  }catch(err){
    toast.error('cloud Service login failed');
    console.error(err);
  }
}
export const cloudTokenExpiry = () => {
  let cloudToken = getItemFromLocal('cloudToken');
  let decodedToken: JwtPayload | null = null;
  
  if (cloudToken) {
    decodedToken = jwtDecode<JwtPayload>(cloudToken);
  }else{
    return true;
  }
  
  const currentTime = Math.floor(Date.now() / 1000); 
  const tokenExpired = decodedToken?.exp !== undefined ? decodedToken.exp < currentTime : true;
  return tokenExpired;

}
export const handleDownload = async (fileData : any) => {
  const s3Data = fileData;
  const s3Url = await getFile(s3Data?.fileName);

  if(!s3Url){
   return
  }

  try {
    const response = await fetch(s3Url);

    const blob = await response.blob();

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);

    const urlParts = s3Url.split("/");
    const fullFileName = urlParts[urlParts.length - 1];
    const [filename] = fullFileName.split("?");

    downloadLink.setAttribute("download", filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const setItemForLocal = (key : string, value : any) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error('Error setting item in localStorage', error);
  }
};

export const getItemFromLocal = (key:string) => {
  try {
    const item = localStorage.getItem(key);
    return item;
  } catch (error) {
    console.error('Error getting item from localStorage', error);
    return null;
  }
};

export const removeItemFromLocal = (key:string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing item from localStorage', error);
  }
};

export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error('Error clearing localStorage', error);
  }
};

export const getRoles = () => {
  let token = getItemFromLocal('authToken');
  if(token){
    let decoded : DecodedToken = jwtDecode(token);
    return decoded.roles;
  }else{
    window.location.href = '/login';
    return [];
  } 
}

export const formatDate = (newdate : string) => {
  const date = new Date(newdate);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}`;
}