import {configureStore} from "@reduxjs/toolkit"
import projectReducer,{ ProjectState } from "./slices/projectSlice"
import loginReducer,{ LoginState } from "./slices/loginSlice";
import documentReducer,{ DocumentsState } from "./slices/documentSlice";
import userReducer,{ UserState } from "./slices/userSlice";

export interface RootState {
    projects: ProjectState;
    login: LoginState;
    document: DocumentsState;
    user:UserState
    // other slices of state
  }

export const store = configureStore({
    reducer :{
      projects:projectReducer,
      login:loginReducer,
      document:documentReducer,
      user:userReducer,
    }
})