import { createSlice } from "@reduxjs/toolkit";
import { Project } from "./projectSlice";
import { GetUsers } from "../../Components/Users/ManageUsers";

export interface User{
    name:string;
    emailId: string;
    mobile: string;
    roles: Array<string|null>;
    projectIds:Array<string|null>;
}

export interface UserState{
    dialogOpen:boolean;
    loading:boolean;
    newUserProjects:Array<Project|null>
    newUser : User;
    selectedProjects : Array<Project|null>;
    emailError  : boolean;
    mobileError : boolean;
    dialogLoading : boolean;
    allUsers : Array<GetUsers|null>;
    userEditId : string;
    isEdit : boolean;
    searchUser : string;
    filteredUsers : Array<GetUsers|null>;
}

const initialState : UserState  = {
   dialogOpen : false,
   loading : false,
   newUserProjects: [],
   newUser : 
    {
        name:"",
        emailId: "",
        mobile: "",
        roles: [],
        projectIds: [] 
    },
    selectedProjects:[],
    emailError : false,
    mobileError : false,
    dialogLoading : false,
    allUsers:[],
    userEditId : '',
    isEdit : false,
    searchUser : '',
    filteredUsers : [],
}

export const userSlice = createSlice({
    name:'useSlice',
    initialState,
    reducers : {
        setDialogOpen : (state,action) => {
            state.dialogOpen = action.payload;
        },
        setUserScreenLoading : (state,action) => {
            state.loading = action.payload;
        },
        setUserProjects:(state,action) => {
            state.newUserProjects = action.payload;
        },
        setUserData:(state,action) => {
            state.newUser = action.payload;
        },
        setSelectedProjects:(state,action) => {
            state.selectedProjects = action.payload;
        },
        setEmailError:(state,action) => {
            state.emailError = action.payload;
        },
        setMobileError:(state,action) => {
            state.mobileError = action.payload;
        },
        setDialogLoading:(state,action) => {
            state.dialogLoading = action.payload;
        },
        setAllUsers:(state,action) => {
            state.allUsers = action.payload;
        },
        setUserEditId:(state,action) => {
            state.userEditId = action.payload;
        },
        setIsEdit:(state,action) => {
            state.isEdit = action.payload;
        },
        setSearchUser:(state,action) => {
            state.searchUser = action.payload;
        },
        setFilteredUsers:(state,action) => {
            state.filteredUsers = action.payload;
        },
        resetUserState: () => initialState,
    }
})

export const {setDialogOpen,setUserScreenLoading,setUserProjects,setUserData,setSelectedProjects,setEmailError,setMobileError,setDialogLoading,setAllUsers,setUserEditId,setIsEdit,setSearchUser,setFilteredUsers,resetUserState} = userSlice.actions;

export default userSlice.reducer;