import { createSlice } from "@reduxjs/toolkit";

export interface LoginState{
    loginEmail : string;
    emailError : boolean;
    otpLoading : boolean;
    otp:Array<string>;
    verifyLoading:boolean;
}

const initialState : LoginState = {
    loginEmail : '',
    emailError : false,
    otpLoading : false,
    otp:['','','',''],
    verifyLoading:false,
}

export const loginSlice = createSlice({
    name: 'loginSlice',
    initialState,
    reducers:{
        setLoginEmail : (state,action) => {
            state.loginEmail = action.payload; 
        },
        setEmailError : (state,action) => {
            state.emailError = action.payload; 
        },
        setOtpLoading : (state,action) => {
            state.otpLoading = action.payload; 
        },
        setOtp:(state,action) => {
            state.otp = action.payload;
        },
        setVerifyLoading:(state,action) => {
            state.verifyLoading = action.payload;
        },
        resetLoginState: () => initialState,
    }
})

export const {setLoginEmail,setEmailError,setOtpLoading,setOtp,setVerifyLoading,resetLoginState} = loginSlice.actions;

export default loginSlice.reducer;